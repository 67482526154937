import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When subscribing to a webhook, Twitch suggests that we should pass a `}<inlineCode parentName="p">{`hub.secret`}</inlineCode>{` param to the request body to authenticate the post requests done on our callback URL.`}</p>
    <p>{`This is important because if someone finds your callback URL, they can make a post request with the same structure as Twitch and trick you to think an event happened when it didn’t.`}</p>
    <p>{`By sending `}<inlineCode parentName="p">{`hub.secret`}</inlineCode>{`, twitch will hash the payload with your secret, allowing you to check the authenticity of the request and confirm that it was sent by Twitch and not some dodgy third-party.`}</p>
    <p>{`Checking `}<a parentName="p" {...{
        "href": "https://dev.twitch.tv/docs/api/webhooks-reference"
      }}>{`Twitch documentation on webhooks`}</a>{`  you can see that Twitch will use your secret to sign the payload and will include an `}<inlineCode parentName="p">{`X-Hub-Signature`}</inlineCode>{` to the header of the request, this signature is generated by `}<inlineCode parentName="p">{`sha256(secret, notification_bytes)`}</inlineCode>{`.`}</p>
    <p>{`When Twitch sends the request you will have to use your  `}<inlineCode parentName="p">{`hub.secret`}</inlineCode>{`   together with the payload received from Twitch to get the sha256 hash. Then you need to compare if this hash is equal to the one received on the `}<inlineCode parentName="p">{`X-Hub-Signature`}</inlineCode>{` header.`}</p>
    <hr></hr>
    <p>{`Note that the `}<inlineCode parentName="p">{`X-Hub-Signature`}</inlineCode>{`  has the following format `}<inlineCode parentName="p">{`sha256=<sha hash>`}</inlineCode>{`.`}</p>
    <hr></hr>
    <p>{`With this information, we can build our validation checker function to see if the request made to our URL did come from Twitch or not.  The thing that we have to keep in mind is that we will always work with bytes.`}</p>
    <p>{`I’m using `}<a parentName="p" {...{
        "href": "https://opsdroid.dev"
      }}>{`opsdroid`}</a>{`  to connect to Twitch and subscribe to webhooks. Opsdroid uses `}<a parentName="p" {...{
        "href": "https://docs.aiohttp.org"
      }}>{`AIOHTTP`}</a>{`  to create the web server as well to handle requests.`}</p>
    <p>{`In aiohttp we can do `}<inlineCode parentName="p">{`await request.read()`}</inlineCode>{` to read the response as bytes, then we just need to create our sha256 hash with this data together with the secret that we passed to `}<inlineCode parentName="p">{`hub.secret`}</inlineCode>{` when subscribed to the webhook.`}</p>
    <p>{`We can achieve this with the `}<inlineCode parentName="p">{`hashlib`}</inlineCode>{` module.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import hashlib

async def validate_request(request, secret):
    # Get x-hub-signature from header and remove 'sha256=' from it
    signature  = request.headers.get("x-hub-signature").replace("sha256=", '')

    payload = await request.read()
    validator = hashlib.sha256()

    # Add payload together with secret encoded to bytes
    validator.update(payload + secret.encode())

    # Get hex representation 
    representation = validate.hexdigest()

    return signature == representation
`}</code></pre>
    <p>{`This function will achieve what we want but seems a bit messy and we could perhaps refactor it a bit more to make it more readable.  We can use the `}<inlineCode parentName="p">{`hmac`}</inlineCode>{` module to pass the payload and the secret as parameters and get the hex representation of our hash.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`async def validate_request(request, secret):
    signature  = request.headers.get("x-hub-signature").replace("sha256=", '')

    payload = await request.read()

    computed_hash = hmac.new(secret.encode(), msg=payload, digestmod=hashlib.sha256).hexdigest()

    return signature == computed_hash
`}</code></pre>
    <p>{`Now that we have our validator working, we can use it to check if a request did come from Twitch and then handle that request. If the request didn’t come from Twitch we can just send an error message back.`}</p>
    <hr></hr>
    <p><em parentName="p">{`References`}</em>{`:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://stackoverflow.com/questions/1306550/calculating-a-sha-hash-with-a-string-secret-key-in-python"
        }}>{`Calculating a SHA hash with a string + secret key in python - Stack Overflow`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://discuss.dev.twitch.tv/t/how-to-use-the-hub-secret-with-web-hooks/13356/2"
        }}>{`How to use the “hub.secret” with web hooks? - API - Twitch Developer Forums`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      